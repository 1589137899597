<template>
  <v-container>
    <tattooer-card>
      <v-row v-if="completed" justify="center">
        <v-col cols="10">
          <v-alert icon="mdi-check" color="success" dark>{{
            $t("tattoer_inscription_complete")
          }}</v-alert>
        </v-col>
        <v-col cols="3">
          <v-btn block color="primary" v-if="$store.getters['auth/isLoggedIn']"
            >GO TO HOME</v-btn
          >
          <v-btn block color="primary" v-else>GO TO LOG IN</v-btn>
        </v-col>
      </v-row>

      <studio-form :studio="studio" @validated="validate"></studio-form>
    </tattooer-card>
  </v-container>
</template>

<script>
import constants from "@/constants";
import { mapActions, mapState } from "vuex";
export default {
  name: "StudioInscriptionInvite",
  components: {
    StudioForm: () => import("@/components/studio/Form"),
    TattooerCard: () => import("@/components/inscription/tattooer/Card"),
  },
  mounted() {
    this.check();
    this.fetchTattooer();
    this.fetchStudio();
    this.fetchRelation();
  },
  computed: {
    ...mapState("inscriptions", ["studioState", "tattooerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },

    studio: {
      get() {
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },
  },
  methods: {
    ...mapActions("auth", ["checkHash"]),
    check() {
      let valors = [];
      if (this.$route.params.tattooer) valors.push(this.$route.params.tattooer);
      valors.push(this.$route.params.studio);
      this.checkHash({ valors, hash: this.$route.params.hash }).then((data) => {
        //console.log(data);
        if (!data.correct) {
          this.$router.push({ name: "404 Error" });
        }
      });
    },
    ...mapActions("studios", ["getStudio"]),
    fetchStudio() {
      this.getStudio(this.$route.params.studio).then((result) => {
        this.studio = result;
      });
    },

    ...mapActions("tattooers", ["getTattooer"]),
    fetchTattooer() {
      this.getTattooer(this.$route.params.tattooer).then((result) => {
        this.tattooer = result;
      });
    },
    ...mapActions("inscriptions", ["getTattooerStudio", "updateStudio"]),
    fetchRelation() {
      this.getTattooerStudio({
        tattooer_id: this.$route.params.tattooer,
        studio_id: this.$route.params.studio,
      }).then((result) => {
        this.$set(this.studio, "tattooerStudio", result);
        //this.tattooer.tattooerStudio = result;
      });
    },
    validate() {
      this.updateStudio({
        tattooer_id: this.studio.id,
        tattooer: this.studio,
      }).then((response) => {
        this.completed = true;
      });
    },
    login() {
      this.$router.push({ name: "Login", params: { inscription: true } });
    },
  },
  data() {
    return {
      e1: 1,
      completed: false,
    };
  },
};
</script>